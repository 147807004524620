// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------
/**
 * The *greatest common divisor* of two values
 *
 * @since 2.0.0
 */
export function gcd(E, field) {
    var zero = field.zero;
    var f = function (x, y) { return (E.equals(y, zero) ? x : f(y, field.mod(x, y))); };
    return f;
}
/**
 * The *least common multiple* of two values
 *
 * @since 2.0.0
 */
export function lcm(E, F) {
    var zero = F.zero;
    var gcdSF = gcd(E, F);
    return function (x, y) { return (E.equals(x, zero) || E.equals(y, zero) ? zero : F.div(F.mul(x, y), gcdSF(x, y))); };
}
// -------------------------------------------------------------------------------------
// deprecated
// -------------------------------------------------------------------------------------
/**
 * Use [`Field`](./number.ts.html#field) instead.
 *
 * @category zone of death
 * @since 2.0.0
 * @deprecated
 */
export var fieldNumber = {
    add: function (x, y) { return x + y; },
    zero: 0,
    mul: function (x, y) { return x * y; },
    one: 1,
    sub: function (x, y) { return x - y; },
    degree: function (_) { return 1; },
    div: function (x, y) { return x / y; },
    mod: function (x, y) { return x % y; }
};
